<template>
  <div class="article-home" v-wechat-title="this.article.title">
    <!-- 点赞，评论等 -->
    <div class="like">
      <!-- 文章赞同 -->
      <approve :username="username" :articleId="''+articleId"></approve>
      <div>
        <span style="display:block;text-align:center">{{this.remarkCount}}</span>
        <el-tooltip class="item" effect="dark" content="评论" placement="right">
          <a href="#comment">
            <el-avatar ref="remark" icon="el-icon-chat-dot-round" alt="评论"></el-avatar>
          </a>
        </el-tooltip>
      </div>
      <!-- 举报 -->
      <accuse :username="username" :article="article"></accuse>
    </div>
    <!-- 文章复制限制 -->
    <copyLimit :article="article"></copyLimit>
    <div class="article-main">
      <!-- 文章主体 -->
      <el-card>
        <span class="article-title">
          <strong>{{article.title}}</strong>
        </span>
        <el-divider content-position="left">
          作者:
          <a href="#" style=" text-decoration: none;" @click="toHomePage()">{{article.name}}</a>
          <el-divider direction="vertical"></el-divider>
          发布时间:{{article.createTime}}
          <el-divider direction="vertical"></el-divider>
          阅读:{{article.viewCount}}
          <router-link
            style="text-decoration:none;"
            v-if="isOwner()"
            :to="{name:'articleEdit',params: { articleId: article.id }}"
          >
            <el-divider direction="vertical"></el-divider>
            <span>修改</span>
          </router-link>
        </el-divider>
        <!-- <div ref="content" v-highlight class="content" id="preview"></div> -->
        <div ref="content" id="content" class="content" v-html="compiledMarkdown"></div>
      </el-card>
      <!-- 评论框 -->
      <el-card>
        <commentBox id="comment" :clearCach="clearCach" :parent="parent" @submit-box="submitBox"></commentBox>
        <commentList :comments="remark" :owner="isOwner()" @update-remark="getArticleRemarkById"></commentList>
      </el-card>
      <recommend v-if="article.title" :title="article.title"></recommend>
    </div>
    <div class="article-aside">
      <div class="aside-box">
        <div class="catalog" style="margin-top:10x">
          目录
          <el-divider></el-divider>
          <ul>
            <template v-for="(item, index) in catalog">
              <li :key="index" :style="'margin-left:'+item.level * 15 + 'px;margin-top:10px;'">
                <a
                  style="text-decoration: none;color: inherit;font-weight:500;"
                  :href="`#${item.id}`"
                  :title="item.title"
                >{{item.title}}</a>
              </li>
            </template>
          </ul>
        </div>
        <div class="his-hot">
          <hisHot :author="article.author">他的热门</hisHot>
        </div>
      </div>
    </div>

    <!-- 预览图片 -->
    <transition>
      <div @click="preview_imgsrc=null" v-if="preview_imgsrc" class="preview">
        <img :src="preview_imgsrc" alt="none" />
      </div>
    </transition>
  </div>
</template>

<script>
import { catalog } from "@/utils/catalog.js";
// import { Reader, Parser } from "@/utils/easy-markdown.js";
import commentList from "@/components/comment/commentList";
import commentBox from "@/components/comment/commentBox";
import approve from "@/components/sideBar/approve";
import accuse from "@/components/sideBar/accuse";
import recommend from "@/components/recommend/recommend";
import copyLimit from "@/components/copyLimit/copyLimit";
import hisHot from "@/components/hisHot/hisHot";
import { encrypt } from "@/utils/encoder.js";
import { markdown } from "@/utils/simple-markdown.js";
// import Clipboard from "clipboard";
export default {
  name: "articleInfo",
  data() {
    return {
      article: {},
      articleList: {},
      catalog: [],
      remark: [],
      parent: {
        id: -1,
        articleId: this.$route.params.articleId
      },
      clearCach: 0,
      remarkCount: 0,
      preview_imgsrc: null
    };
  },
  components: {
    commentBox,
    commentList,
    approve,
    accuse,
    copyLimit,
    recommend,
    hisHot
  },
  computed: {
    compiledMarkdown() {
      let result = markdown.render(this.article.content || "");
      return result;
    },
    username() {
      return this.$store.state.permission.user.username;
    },
    articleId() {
      return this.$route.params.articleId;
    }
  },
  async created() {
    await this.getArticleInfoById(this.$route.params.articleId);
    await this.getArticleRemarkById();

    this.catalog = catalog(this.$refs["content"]);
    this.imgPriview();
  },
  mounted() {},
  methods: {
    async getArticleInfoById(articleId) {
      // 加载文章信息
      const { data: res } = await this.$api.getArticleInfoById(articleId);
      this.article = res.data;
      this.setMate(this.article);
    },
    // 获取文章评论
    async getArticleRemarkById() {
      let value = this.$route.params.articleId;
      const { data: res } = await this.$api.getArticleRemarkById(value);
      // this.$store.dispatch('',value)
      this.remark = res.data;
      const { data: resCount } = await this.$api.getArticleRemarkCount(value);
      this.remarkCount = resCount.data;
    },
    // 提交评论
    async submitBox(msg) {
      const { data: res } = await this.$api.addArticleRemark(msg);
      this.remark.push(res.data);
      // 清除缓存
      if (this.clearCach == 0) {
        this.clearCach = 1;
      } else {
        this.clearCach = 0;
      }
    },
    // 跳转个人首页
    toHomePage() {
      this.$router.push({
        name: "homepage",
        params: { username: encrypt(this.article.author) }
      });
    },
    isOwner() {
      if (this.article.author == this.username) {
        return true;
      } else {
        return false;
      }
    },
    // 设置文章标题
    setMate(value) {
      document.title = this.article.title;
      let head = document.getElementsByTagName("head");
      let meta = document.createElement("meta");
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", value.title);
      if (this.article.digest) {
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", value.digest);
      }
      head[0].appendChild(meta);
    },
    imgPriview() {
      this.$el.addEventListener("click", event => {
        event = event ? event : window.event;
        let ele = event.srcElement ? event.srcElement : event.target;
        if (ele.tagName === "IMG") {
          if (!this.preview_imgsrc) {
            this.preview_imgsrc = ele.src;
          } else {
            this.preview_imgsrc = null;
          }
        }
      });
    }
  }
};
</script>
 
<style lang="less" scoped>
@import "../../assets/css/easy-markdown.css";
/* @import "../../assets/css/editor.css"; */
.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity ease 0.3s;
  /* pointer-events: none; */
}
.article-home {
  display: flex;
  width: 1500px;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  .like {
    width: 50px;
    height: 185px;
    top: 200px;
    margin-right: 1200px;
    /* background-color: blue; */
    position: fixed;
    z-index: 2000;
    text-align: center;
  }
  .article-main {
    width: 900px;
    margin-right: 20px;
    .article-title {
      font-size: 22px;
      color: #bf360c;
    }
    .content {
      font-size: 16px;
    }
  }
  .article-aside {
    width: 250px;
    top: 80px;
    .aside-box {
      position: fixed;
      max-width: 250px;
      .catalog {
        max-height: 400px;
        font-size: 12px;
        overflow-y: auto;
      }
      .his-hot{
        font-size: 12px;
      }
    }
  }
}

.el-card {
  flex: 1;
}
</style>

