<template>
  <div class="hot-home">
    <slot></slot>
    <el-divider></el-divider>
    <div class="hot-box" v-for="item in articleList" :key="item.id">
      <div class="hot-title">
        <router-link
        target="_blank"
          :to="{name:'articleInfo',params: { articleId: item.id }}"
        >{{item.title}}</router-link>
      </div>
      <div class="hot-approve">
        <i class="iconfont decorate">&#xe73c;{{item.approveCount}}</i>
        <i class="iconfont decorate">&#xe663;{{item.remarkCount}}</i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hisHot",
  props: {
    author: {
      type: String
    }
  },
  data() {
    return {
      articleList: []
    };
  },
  watch:{
      author(){
          this.getArticleRank();
      }
  },
  mounted() {
    this.getArticleRank();
  },
  methods: {
    // 获取排名文章
    async getArticleRank() {
      let params = {
        pageNum: 1,
        pageSize: 10,
        author: this.author,
        orderby: "approve_count desc, create_time desc"
      };
      const { data: res } = await this.$api.getArticleRank(params);
      this.articleList = res.data.list;
    }
  }
};
</script>

<style lang="less" scoped>
.hot-home {
  .hot-box {
    margin-top: 4px;
    .hot-title {
      font-size: 10px;
      a {
        text-decoration: none;
        color: #000;

        &:hover {
          color: #50bae7;
        }
      }
    }
  }

  .hot-approve {
    display: flex;
    justify-content: space-between;
    .decorate {
      width: 30%;
      font-size: 12px;
      color: #99b0c7;
    }
  }
}
</style>